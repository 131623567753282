import {useState, useEffect, useRef, Children, isValidElement, cloneElement } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

/** Insert a form that fetches data from the backend.
*/
function Form({formName, fields, action, submitText, draftText, onSuccess, mode, doNotFetchInitials, setFormValues, setInitialValues, nsp, children}) {
	const {t, darkMode} = nsp;
	const inputFieldTypes = [
		(<Input />).type.name,
		(<DatePicker />).type.name
	];

	if (!fields) {
		fields = [];
	}

	let inputFields = [];
	const getFormFields = (c) => Children.forEach(c, (child) => {
		if (isValidElement(child)) {
			if (child.props.children) {
				getFormFields(child.props.children);
			}
			if (inputFieldTypes.includes(child.type.name)) {
				let validate;
				if (child.props.type === 'email') {
					validate = 'email';
				}
				inputFields.push({
					name: child.props.name,
					default: child.props.value,
					type: child.props.type,
					mandatory: child.props.mandatory,
				});
			}
		}
		return child;
	})
	getFormFields(children);

	const [values, setValue] = useState(() => {
		let startValues = {};
		fields.concat(inputFields).forEach((el) => { 
			startValues[el.name] = el.default ? el.default : "";
			if (el.type === "datepicker") {
				startValues[el.name + '-date'] = "";
				startValues[el.name + '-time'] = "";
			}
		});
		return startValues;
	});

	const updateValue = (newValue) => {
		setValue(newValue);

		if (setFormValues) {
			setFormValues(newValue);
		}
	}

	const [tinymceValues, updateTinymceValue] = useState(() => {
		let startValues = {};
		fields.concat(inputFields).forEach((el) => {
			if (el.type === "editor") {
				startValues[el.name] = el.default ? el.default : "";
			}
		});
		return startValues;
	});
	const [buttonClicked, setButtonClicked] = useState(false);

	const [errorMessages, setErrorMessages] = useState({});
	const [originalFields, setOriginalFields] = useState({});

	const [alteredFields, setAlteredFields] = useState([]);
	
	const [currentlyLoadingStuff, setCurrentlyLoadingStuff] = useState(false);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

	const [disableInputFields, setDisableInputFields] = useState((mode === "edit" && !doNotFetchInitials) ? true : false);

	const [isUnmounted, setUnmounted] = useState(false);

	const editorRef = useRef(null);

	const [isPublished, setIsPublished] = useState(0);

	useEffect(() => {
		checkValue(false);
		if (setFormValues) {
			setFormValues(values);
		}

		if (mode === "edit" && !doNotFetchInitials) {
			const getInitialValues = async () => {
				try {
					const res = await fetch(action + '?context=edit', {
						method: "GET",
						mode: 'cors',
						credentials: "include",
					});
					const json = await res.json();
					if (setInitialValues) {
						setInitialValues(json);
					}
					if (json.error) {
						let errorField = 'GENERAL';
						if (json.errorfield) {
							errorField = json.errorfield;
						}
						setErrorMessages(errorMessages => {
							let newErrorMessages = { ...errorMessages };
							newErrorMessages[errorField] = json.error;
							return newErrorMessages;
						});
					}
					else {
						let existingValues = { ...values };
						let existingTinymceValues = { ...tinymceValues };
						fields.concat(inputFields).forEach((el) => {
							if (json.data[el.name]) {
								existingValues[el.name] = json.data[el.name];
								if (el.type === 'datepicker' && json.data[el.name]) {
									let datetime = json.data[el.name].split(' ');
									existingValues[el.name + '-date'] = datetime[0];
									existingValues[el.name + '-time'] = datetime[1];
									existingValues[el.name] = 'date';
								}
								if (el.type === 'editor') {
									existingTinymceValues[el.name] = json.data[el.name];
								}
								if (el.name === 'published') {
									setIsPublished(json.data[el.name]);
								}
							}
						});
						updateValue(existingValues);
						setOriginalFields(existingValues);
						updateTinymceValue(existingTinymceValues);
					}
				}
				catch (e) {
					setErrorMessages(errorMessages => {
						let newErrorMessages = { ...errorMessages };
						newErrorMessages['GENERAL'] = t['ERROR_UNEXPECTED_ERROR'];
						console.log(e);
						return newErrorMessages;
					});
				}
				finally {
					setDisableInputFields(false);
				}
			}
			getInitialValues();
		}
		return (() => setUnmounted(true));
	}, []);

	useEffect(() => {
		let newValues = {...values};
		let existingValues = Object.keys(values);
		inputFields.forEach(el => {
			if ( !existingValues.includes(el.name) || el.type === 'hidden' ) {
				newValues[el.name] = el.default;
			}
		})
		setValue(newValues);
		
	}, [children]);

	function checkValue(field, unlockOnly = false) {
		let formIsValid = true;
		fields.concat(inputFields).forEach((el) => {
			let error = false;
			let valueToCheck = (unlockOnly !== false && field === el.name) ? unlockOnly : values[el.name];
			if (el.mandatory && !valueToCheck) {
				formIsValid = false;
				error = 'MANDATORY_FIELD';
			}
			else if (el.validate === 'email' && !isValidEmail(valueToCheck)) {
				formIsValid = false;
				error = 'EMAIL_NOT_VALID';
			}
			else if (el.validate === 'notemail' && isValidEmail(valueToCheck)) {
				formIsValid = false;
				error = 'USERNAME_CANNOT_BE_EMAIL';
			}
			else if (el.validate === 'compare' && el.compare && valueToCheck !== values[el.compare]) {
				formIsValid = false;
				error = "PASSWORDS_DO_NOT_MATCH";
			}
			else if (el.validate === 'regex' && !el.regex.test(valueToCheck)) {
				formIsValid = false;
				error = 'ILLEGAL_CHARACTERS';
			}
			if ((field === 'ALL' || field === el.name) && error !== errorMessages[el.name] && (error === false || unlockOnly === false)) {
				setErrorMessages(errorMessages => {
					let newErrorMessages = { ...errorMessages };
					newErrorMessages[el.name] = error;
					return newErrorMessages;
				});
			}
		});

		setSubmitButtonDisabled(!formIsValid);
	}

	const changeValue = (e) => {
		let {name, type, value, checked} = e.target;
		if (type === 'checkbox') {
			value = checked;
		}
		updateValue(values => {
			let newValues = { ...values };
			newValues[name] = value;
			return newValues;
		});
		checkValue(name, value);
		if (!alteredFields.includes(name)) {
			let newAlteredFields = [...alteredFields, name];
			setAlteredFields(newAlteredFields);
		}
		else if (value === originalFields[name]) {
			let newAlteredFields = [...alteredFields];
			let index = newAlteredFields.indexOf(name);
			newAlteredFields.splice(index, 1);
			setAlteredFields(newAlteredFields);
		}
	}

	const modifiedChildren = (c) => Children.map(c, child => {
		if (isValidElement(child)) {
			if (child.props.children && child.props.type !== 'select') {
				return cloneElement(child, {...child.props, children: modifiedChildren(child.props.children)});
			}
			if (inputFieldTypes.includes(child.type.name)) {
				return cloneElement(child, {
					...child.props,
					value: values[child.props.name],
					form: formName,
					nsp: nsp,
					onChange: changeValue,
					onBlur: () => checkValue(child.props.name),
					altered: alteredFields.includes(child.props.name),
					error: t['ERROR_' + errorMessages[child.props.name]],
					darkMode: darkMode,
					tinymceValues: tinymceValues,
					editorRef: editorRef,
					originalValue: typeof(originalFields) === 'object' ? originalFields[child.props.name] : '',
				 })
			}
		}
		return child;
	});

	const formFields = modifiedChildren(children);

	const skjemafelt = fields.map((el, index) => {  
		let inputType = el.type ? el.type : "text";
		let TagType = "input";
		if (inputType === "textarea") {
			TagType = "textarea";
		}
		else if (inputType === "textarea") {
			TagType = "textarea";
		}

		let tag;
		if (inputType === "select") {
			tag = <p><select 
				name={el.name}
				id={`${formName}-${el.name}`}
				value={values[el.name]}
				onChange={changeValue} 
				onBlur={() => checkValue(el.name)}
				disabled={disableInputFields}
				className={`${alteredFields.includes(el.name) ? " altered" : ""}`}>
					{el.options.map((el) => <option key={el.value} value={el.value}>{el.text}</option>)}
			</select></p>

		}
		else if (inputType === "editor" && !disableInputFields) {
			tag = <p><Editor
				textareaName={el.name}
				value={values[el.name]}
				onEditorChange={(value, editor) => changeValue({target: {name: el.name, value: value}})}
				tinymceScriptSrc="/tinymce/tinymce.min.js"
				onInit={(evt, editor) => editorRef.current = editor}
				onBlur={() => checkValue(el.name)}
				disabled={disableInputFields}
				initialValue={tinymceValues[el.name]}
				id={`${formName}-${el.name}`}
				init={{
					menubar: false,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
					],
					toolbar: 'undo redo | bold italic link image | formatselect | bullist numlist | code',
					skin: darkMode ? 'oxide-dark' : '',
					content_css: darkMode ? 'dark' : '',
				}}
			/></p>
		}
		else if (inputType === 'datepicker') {
			tag = <div className="datepicker-wrapper">
				<div className={isPublished ? 'invisible' : ''}>
					<p><input type="radio" name={el.name} value="now" id={`${el.name}-type-now`} onChange={changeValue} checked={values[el.name] === 'now'} /><label htmlFor={`${el.name}-type-now`}>{" " + t['IMMEDIATELY']}</label></p>
					<p><input type="radio" name={el.name} onChange={changeValue} checked={values[el.name] === 'date'} value="date" id={`${el.name}-type-date`} /><label htmlFor={`${el.name}-type-date`}>{" " + t['SPECIFY_DATETIME']}</label></p>
				</div>
				<div className={values[el.name] === "now" ? "grey-box" : "invisible"}>
					{t['WILL_BE_PUBLISHED_IMMEDIATELY'].replace('{button}', submitText)}
				</div>
				<div className={values[el.name] === "date" ? "" : "invisible"}>
					<p><input
						type="date"
						name={`${el.name}-date`}
						value={values[`${el.name}-date`]}
						onChange={changeValue} 
						onBlur={() => checkValue(`${el.name}-date`)}
						disabled={disableInputFields}
						className={`${alteredFields.includes(`${el.name}-date`) ? " altered" : ""}`}
						id={`${formName}-${el.name}-date`}
					/></p>
					<p><input
						type="time"
						name={`${el.name}-time`}
						value={values[`${el.name}-time`]}
						onChange={changeValue} 
						onBlur={() => checkValue(`${el.name}-time`)}
						disabled={disableInputFields}
						className={`${alteredFields.includes(`${el.name}-time`) ? " altered" : ""}`}
						id={`${formName}-${el.name}-time`}
					/></p>
				</div>
			</div>
		}
		else {
			tag = <p><TagType 
				type={inputType}
				placeholder={el.label}
				name={el.name}
				value={values[el.name]}
				onChange={changeValue} 
				onBlur={() => checkValue(el.name)}
				disabled={disableInputFields}
				className={`${alteredFields.includes(el.name) ? " altered" : ""}`}
				id={`${formName}-${el.name}`}
			/></p>
		}
		return (<div key={index} className={`form-element ${inputType === "hidden" ? "invisible" : ""}`}>
			<p className="label"><label htmlFor={`${formName}-${el.name}`}>{el.label}</label> {el.mandatory ? <span className="mandatory-asterisk">*</span> : ""} {disableInputFields ? <span className="loading"><Icon icon={faSpinner} /></span> : ""}</p>
			{tag}
			{el.description ? <p className="input-description">{el.description}</p> : ""}
			{errorMessages[el.name] ? <p className="error-msg"><Icon icon={faExclamationTriangle} className="error-icon" />{t[`ERROR_${errorMessages[el.name]}`]}</p> : ""}
		</div>);
	});

	const postFormData = async (e) => {
		e.preventDefault();
		setErrorMessages(errorMessages => {
			let newErrorMessages = { ...errorMessages };
			newErrorMessages['GENERAL'] = false;
			return newErrorMessages;
		});

		let fetchOptions = {
			mode: 'cors',
			credentials: 'include',
		};

		if (mode === "edit"|| mode === "delete") {
			let data = {};
			alteredFields.forEach(val => {
				data[val] = values[val];
			});
			fields.concat(inputFields).forEach((el) => { 
				if (el.type === "hidden") {
					data[el.name] = el.default;
				}
			});
			data['submitType'] = buttonClicked;

	
			if (mode === "delete") {
				fetchOptions.method = 'DELETE';
			}
			else {
				fetchOptions.method = 'PATCH';
			}
			fetchOptions.body = JSON.stringify(data);
			fetchOptions.headers = {'Content-Type':'application/json', 'Accept': 'application/json'};
		}
		else {
			let theForm = document.getElementById(formName);
			let formData = new FormData(theForm);
			formData.append('submitType', buttonClicked);

			fetchOptions.method = 'POST';
			fetchOptions.body = formData;
		}
		setCurrentlyLoadingStuff(true);
		const postData = async () => {
			try {
				const res = await fetch(
					action, fetchOptions
				)
				const jsonData = await res.json();
				if (jsonData.error) {
					let errorArray = [];

					if (Array.isArray(jsonData.error)) {
						errorArray = jsonData.error;
					}
					else {
						errorArray.push(jsonData.error + (jsonData.errorfield ? '.' + jsonData.errorfield : ''));

					}
					let newErrorMessages = { ...errorMessages };
					errorArray.forEach((el) => {
						let error = el.split('.');
						if (error)
						newErrorMessages[error[1] ? error[1] : "GENERAL"] = error[0];
					});
					setErrorMessages(newErrorMessages);
				}
				else {
					if (jsonData.changes) {
						let newAlteredFields = alteredFields.filter((field) => typeof jsonData.changes[field] === undefined);
						setAlteredFields(newAlteredFields);
					}
					onSuccess(jsonData);
				}
			}
			catch(e) {
				setErrorMessages(errorMessages => {
					let newErrorMessages = { ...errorMessages };
					newErrorMessages['GENERAL'] = 'UNEXPECTED_ERROR';
					console.log(e);
					setCurrentlyLoadingStuff(false);
					return newErrorMessages;
				});
			}
			finally {
				if (!isUnmounted) {
					setCurrentlyLoadingStuff(false);
				}
			}
			// setComics(jsonData.data);
		}
		postData();

	};


	return(
		<form id={formName} onSubmit={postFormData}>
			{skjemafelt}
			{formFields}
			<p>{ currentlyLoadingStuff ? <span className="loading"><Icon icon={faSpinner} /></span> : <>
				<button type="submit" name="submitType" value="publish" disabled={submitButtonDisabled ? true : false} onClick={() => setButtonClicked('publish')}>{submitText}</button>
				{
					draftText ? <button type="submit" name="submitType" value="draft" disabled={submitButtonDisabled ? true : false} onClick={(e) => setButtonClicked('draft')}>{draftText}</button> : ""

				}
			</> }</p>
			{errorMessages['GENERAL'] ? <p className="error-msg"><Icon icon={faExclamationTriangle} className="error-icon" />{t[`ERROR_${errorMessages['GENERAL']}`]}</p> : ""}
		</form>
	);
}

Form.propTypes = {
	/** The id attribute of the form tag. Can not contain whitespaces or start with a number, and if you have multiple forms on the same webpage they can not have the same formName. */
	formName: PropTypes.string.isRequired, 
	/** Array of objects, one for each input field in the form. */
	fields: PropTypes.array,
	/** URL to the API script to be called. */
	action: PropTypes.string.isRequired,
	/** The text to be displayed on the submit button. */
	submitText: PropTypes.string.isRequired, 
	/** A function that will be called when the form is submitted and receives a result without errors. The returned data will be sent to the function as a parameter. */
	onSuccess: PropTypes.func.isRequired,
	/** Can be "new", if you want to post new data to the API or "edit" if you want to change existing data. */
	mode: PropTypes.string, 
	/** If mode is "edit" and the form only contains fields we don't need to fetch from the API, set this to true. */
	doNotFetchInitials: PropTypes.bool, 
	/** A language object containing all the translated strings for the user's chosen language. */
	nsp: PropTypes.object.isRequired,
}

function Input(props) {
	const inputTypes = ['textarea', 'select'];

	let TagType;

	let editorAttributes = {};
	
	if (inputTypes.includes(props.type)) {
		TagType = props.type;
	}
	else if (props.type === 'editor') {
		TagType = (<Editor />).type;

		editorAttributes = {
			textareaName: props.name,
			onEditorChange: (value, editor) => props.onChange({target: {name: props.name, value: value}}),
			tinymceScriptSrc: "/tinymce/tinymce.min.js",
			onInit: (evt, editor) => props.editorRef.current = editor,
			initialValue: props.tinymceValues[props.name],
			init: {
				menubar: false,
				plugins: [
					'advlist autolink lists link image charmap print preview anchor',
					'searchreplace visualblocks code fullscreen',
					'insertdatetime media table paste code help wordcount'
				],
				toolbar: 'undo redo | bold italic link image | formatselect | bullist numlist | code',
				skin: props.darkMode ? 'oxide-dark' : '',
				content_css: props.darkMode ? 'dark' : '',
			},
		};
	}
	else {
		TagType = 'input';
	}

	const tag = <TagType 
		type={TagType === 'input' ? (props.type ? props.type : "text") : undefined} 
		name={props.name} 
		value={props.type === 'radio' ? props.fixedValue : props.value} 
		id={`${props.form + '-' + props.name}${props.type === 'radio' ? '-' + props.fixedValue : ''}`} 
		onChange={props.onChange} 
		placeholder={props.label} 
		onBlur={props.onBlur} 
		disabled={props.disabled} 
		className={props.className + (props.altered ? " altered" : "")}
		checked={(props.type === 'radio' && props.value === props.fixedValue) || (props.type === 'checkbox' && props.value) ? true : false}
		{...editorAttributes}
	>{props.type === 'select' ? props.children : null}</TagType>;

	return <div className={`form-element${ props.type === 'hidden' ? ' invisible' : ''} ${ props.containerClass ? props.containerClass : '' }`} id={`${props.form}-${props.name}-container`}>
		<p className={props.type === 'checkbox' || props.type === 'radio' ? '' : 'label'}>{props.type === 'checkbox' || props.type === 'radio' ? tag : ""} <label htmlFor={`${props.form}-${props.name}${props.type === 'radio' ? '-' + props.fixedValue : ''}`}>{props.label}</label> {props.mandatory ? <span className="mandatory-asterisk">*</span> : ""} {props.disabled ? <span className="loading"><Icon icon={faSpinner} /></span> : ""}</p>

		{
			props.type === 'checkbox' || props.type === 'radio' ? '' : <p>{tag}</p>
		}
		

		{props.description ? <p className="input-description">{props.description}</p> : ""}
		{props.error ? <p className="error-msg"><Icon icon={faExclamationTriangle} className="error-icon" />{props.error}</p> : ""}
	</div>
}

function DatePicker(props) {
	// /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01]) (?:[01][0-9]|2[0-4]):[0-5][0-9](?::[0-5][0-9])?$/

	const [valDate, valTime] = props.value && /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01]) (?:[01][0-9]|2[0-4]):[0-5][0-9](?::[0-5][0-9])?$/.test(props.value) ? props.value.split(' ') : ['', ''];

	const [values, setValues] = useState({
		radio: valDate ? 'date' : 'now',
		date: valDate,
		time: valTime,
	});

	useEffect(() => {
		const [valDate, valTime] = props.value && /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01]) (?:[01][0-9]|2[0-4]):[0-5][0-9](?::[0-5][0-9])?$/.test(props.value) ? props.value.split(' ') : ['', ''];
		setValues({
			radio: valDate ? 'date' : 'now',
			date: valDate,
			time: valTime,
		});
	}, [props.originalValue]);

	const [completeTime, setCompleteTime] = useState('');

	const {t} = props.nsp;

	const update = (value) => {
		props.onChange({ target: { name: props.name, value: value } });
		setCompleteTime(value);
	};

	const changeValue = el => {
		let newValues =  {...values};
		newValues[el.target.type] = el.target.value;
		setValues(newValues); 

		if (el.target.type === 'radio' && el.target.value === 'now') {
			update('');
			return;
		}

		let newDate = el.target.type === 'date' ? el.target.value : values.date;
		let newTime = el.target.type === 'time' ? el.target.value : values.time;

		if (!newTime && !newDate) {
			update('');
			return;
		}

		if (!newDate || !/^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/.test(newDate)) {
			const rightNow = new Date();

			let day = rightNow.getDate();
			if (day.toString().length < 2) {
				day = '0' + day;
			}
			let month = rightNow.getMonth() + 1;
			if (month.toString().length < 2) {
				month = '0' + month;
			}
			let year = rightNow.getFullYear();
			newDate = [year, month, day].join('-');
		}
		
		if (!newTime || !/^(?:[01][0-9]|2[0-4]):[0-5][0-9](?::[0-5][0-9])?$/.test(newTime)) {
			newTime = '00:00:00'
		}

		if (newTime.length === 5) {
			newTime = newTime + ':00';
		}

		update(`${newDate} ${newTime}`);
	}

	return <fieldset className="datepicker-wrapper">
		<input type="hidden" name={props.name} value={completeTime} />
		<div className={`form-element ${ props.containerClass ? props.containerClass : '' }`} id={`${props.form}-${props.name}-container`}>
			<p className="label"><label htmlFor={`${props.form}-${props.name}-radio`}>{t['PUBLISH_TIME']}</label> {props.mandatory ? <span className="mandatory-asterisk">*</span> : ""} {props.disabled ? <span className="loading"><Icon icon={faSpinner} /></span> : ""}</p>

			<Input type="radio" name={props.name + '-radio'} fixedValue="now" form={props.form} label={t['IMMEDIATELY']} onChange={changeValue} value={values.radio} />
			<Input type="radio" name={props.name + '-radio'} fixedValue="date" form={props.form} label={t['SPECIFY_DATETIME']} onChange={changeValue} value={values.radio} />

			{props.description ? <p className="input-description">{props.description}</p> : ""}
			{props.error ? <p className="error-msg"><Icon icon={faExclamationTriangle} className="error-icon" />{props.error}</p> : ""}
		</div>

		{values.radio === 'date' ? <Input label={t['DATE']} type="date" name={`${props.name}-date`} value={values.date} form={props.form} altered={props.altered} disabled={props.disabled} onChange={changeValue} /> : ''}
		{values.radio === 'date' ? <Input label={t['TIME']} type="time" name={`${props.name}-time`} value={values.time} form={props.form} altered={props.altered} disabled={props.disabled} onChange={changeValue} />: ''}
	</fieldset>
}

function isValidEmail(addr) {
	return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(addr);
}
export { Input, DatePicker }
export default Form;