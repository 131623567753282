import {useState} from 'react';
import Comics from '../components/Comics';
// import Striper from '../components/Striper';
import {Link} from 'react-router-dom'; 
import Updates from '../components/Updates';
import DevTools from '../DevTools';
import Comments from '../components/Comments';

function Frontpage({nsp}) {
	const {t, fpCache} = nsp;
	const [comicRefresh, setComicRefresh] = useState(true);

	return(
		<div className="frontpage">
			<h2 className="main-title">Startside</h2>
			<div className="frontpage-random-comic">
				<p className="teaser">{t['RANDOM_COMIC_STRIP']}</p>
				<Updates nsp={nsp} type="c" count="1" comic="random" fields={['h3:comicname', 'creator', 'linkimage']} refresh={comicRefresh} />
				<p className="refresh-link">
					<button className="link" onClick={() => setComicRefresh(!comicRefresh)}>{t['SHOW_ANOTHER_RANDOM_COMIC']}</button>
				</p>
			</div>
			<section className="front-page-ad">
				<p>{t['WELCOME_TO_NETTSERIER']}</p>
				<section className="front-page-ad-buttons">
					<Link to="/n/seriar/">{t['READ_COMICS']}</Link>
					<Link to="/n/meg/seriar/ny/">{t['MAKE_COMICS']}</Link>
					<Link to="/n/om/">{t['LEARN_MORE']}</Link>
				</section>
			</section>
			<section className="nylege front-page-comic-grid">
				<h3>{t['RECENTLY_UPDATED_COMICS']}</h3>
				<Comics nsp={nsp} sort="updated-desc" count="12" cache={true} value={fpCache.recent} setValue={fpCache.setRecent} />	
			</section>
			<section className="front-page-albums"><h3>{t['LATEST_ALBUMS']}</h3>
				<Updates nsp={nsp} count="6" type="a" fields={['comicname', 'thumb', 'title']} className="album-shelf" />
			</section>
			<section className="front-page-comic-grid">
				<h3>{t['POPULAR_COMICS']}</h3>
				<Comics nsp={nsp} sort="score" count="12" cache={true} value={fpCache.popular} setValue={fpCache.setPopular} />
			</section>
			<section className="front-page-news">
			<p className="teaser">{t['NEW_NETTSERIER_BLOG']}</p>
			<Updates nsp={nsp} comic="1395" type="b" fields={["h3", "abstract", "readMore"]} count="1" />
			</section>
			<div className="front-page-columns">
				<section><h3>{t['NEW_COMICS']}</h3>
					<Comics nsp={nsp} count="6" sort="created-desc" />
					<Link to={'/n/' + t['/COMICS'] + '/'} className="button" >{t['SHOW_MORE']}</Link>
				</section>
				<section>
					<h3>{t['LATEST_BLOG_POSTS']}</h3>
					<Updates nsp={nsp} count="6" type="b" fields={["comicname", "h4", "abstract"]} />
					<Link to={'/n/' + t['/BLOG'] + '/'} className="button" >{t['SHOW_MORE']}</Link>
				</section>
				<section><h3>{t['LATEST_COMMENTS']}</h3>
					<Comments nsp={nsp} type="frontpage" count="6" />
					<Link to={'/n/' + t['/COMMENTS'] + '/'} className="button" >{t['SHOW_MORE']}</Link>
				</section>
			</div>
		</div>
	);
}

export default Frontpage;