import {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {createGlobalStyle} from 'styled-components';
import { Helmet } from 'react-helmet';
import Header from '../layoutElements/Header';
import MainMenu from '../layoutElements/MainMenu';
import NotFound from '../pages/NotFound';
import PageFooter from '../components/PageFooter';
import ComicMain from './ComicMain';
import Loading from '../components/Loading';
import ComicMenu from './ComicMenu';
import HeaderButtons from '../layoutElements/HeaderButtons';
import CreatorList from '../components/CreatorList';
import ComicSubmenu from './ComicSubmenu';
import BlockedComic from './BlockedComic';

function ComicWrapper({nsp}) {
	const {mainDesignLoaded, site, t} = nsp;
	const [theComic, setTheComic] = useState(undefined);
	const [comicLoaded, setComicLoaded] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const {url} = useParams();

	useEffect(() => {
		const getComic = async () => {
			let res = await fetch(`${site.api}comics/_${url}/?view=layout`, {credentials: 'include'});
			let json = await res.json();
			let loadedComic = json.data;
			setTheComic(loadedComic);
			setComicLoaded(true);
		} 
		getComic();
	}, [url]);

	const styles = {
	}

	if (theComic && theComic.styles) {

	}
	const GlobalStyles = createGlobalStyle`
	body {
		${Object.keys(styles).map(el => {
			return '--' + el + ': ' + styles[el] + ';';
		}).join("\n")}
	}
	`;

	if (theComic) {
		return <div className="nettserier-wrapper">
			<GlobalStyles />
		<header>
			<div className="comic-meta">
				{/* Title */}
				<h1>{theComic.name}</h1>

				{/* Creator list */}
				<CreatorList nsp={nsp} creator={theComic.creator} />
			</div>
			<HeaderButtons nsp={nsp} />
		</header>
		<ComicMenu nsp={nsp} theComic={theComic} />
		<ComicSubmenu nsp={nsp} theComic={theComic} setIsBlocked={setIsBlocked} />
		{isBlocked ? <BlockedComic nsp={nsp} /> : <ComicMain nsp={nsp} theComic={theComic} />}
		<footer>
		<nav>
				<ul>
					<li><Link to={`/n/${t['/HELP']}/${t['/PRIVACY']}/`}>{t['PRIVACY_POLICY']}</Link></li>
					<li><Link to={`/n/${t['/HELP']}/${t['/COOKIES']}/`}>{t['COOKIES']}</Link></li>
				</ul>
			</nav> 
			<div></div>
			<div className="copyright">{theComic.creator.length ? <p>&copy; {theComic.creator.map(cr => cr.name).join(' / ')}</p> : ''}</div>
		</footer>
	</div>;
	}

	if (comicLoaded || mainDesignLoaded) {
		return <div className="nettserier-wrapper">
			<Header nsp={nsp} />
			<MainMenu nsp={nsp} />
			<main className="main">
				{comicLoaded ? <NotFound nsp={nsp} /> : <Loading />}
			</main>
			<PageFooter nsp={nsp} />
		</div>
	}

	return (
		<Loading myClassName="loading-comic-page" />
	)
}

export default ComicWrapper
